import { useLocales } from '@sevenrooms/core/locales'
import { ActionsButton } from '@sevenrooms/core/ui-kit/form'
import { HStack } from '@sevenrooms/core/ui-kit/layout'
import { accessRulesMessages } from './accessRules.locales'
import { ViewActivityLogButton } from './ViewActivityLogButton'
import type { ShiftDetails } from './types'

interface ListViewActionButtonsProps {
  shiftCategoryOptions: Record<string, string>
  date: Date
  shiftDetails: ShiftDetails[] | null
  onClick: (shift: ShiftDetails | null, date: Date) => void
}

export function ListViewActionButtons({ shiftCategoryOptions, shiftDetails, date, onClick }: ListViewActionButtonsProps) {
  const { formatMessage } = useLocales()

  const actionOptions =
    shiftDetails?.map(shift => ({
      id: shift.category,
      title: shiftCategoryOptions[shift.category] ?? '',
      onClick: () => onClick(shift, date),
    })) ?? []
  actionOptions.unshift({
    id: '',
    title: formatMessage(accessRulesMessages.createAccessRule),
    onClick: () => onClick(null, date),
  })

  return (
    <HStack m="none" spacing="m">
      <ViewActivityLogButton />
      <ActionsButton icon="VMSWeb-edit" variant="primary" actions={actionOptions} menuWidth="150px" data-test="list-view-actions-button" />
    </HStack>
  )
}

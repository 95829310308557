import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Flex } from '@sevenrooms/core/ui-kit/layout'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { accessRulesMessages } from './accessRules.locales'

export function SwitchToWeekViewButton() {
  const { formatMessage } = useLocales()
  const { venueUrlKey } = useAppContext()

  const link = `${window.location.origin}/manager/${venueUrlKey}/manage/capacity/accessrules`

  return (
    <Flex ml="m" alignItems="center">
      <Button variant="secondary" onClick={() => window.location.assign(link)} data-test="switch-to-week-view-button">
        {formatMessage(accessRulesMessages.switchToWeekView)}
      </Button>
    </Flex>
  )
}

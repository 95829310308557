import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { HStack } from '@sevenrooms/core/ui-kit/layout'
import { accessRulesMessages } from './accessRules.locales'
import { ViewActivityLogButton } from './ViewActivityLogButton'

interface WeekViewActionButtonsProps {
  date: Date
  onClick: (date: Date) => void
}

export function WeekViewActionButtons({ date, onClick }: WeekViewActionButtonsProps) {
  const { formatMessage } = useLocales()

  return (
    <HStack m="xs" spacing="sm">
      <ViewActivityLogButton isWeekView />
      <Button size="s" onClick={() => onClick(date)} icon="VMSWeb-edit" data-test="create-access-rule-button">
        {formatMessage(accessRulesMessages.createAccessRule)}
      </Button>
    </HStack>
  )
}
